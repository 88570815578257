import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import TalkContent from "components/talks/TalkContent";
import TweetWrapper from "components/common/TweetWrapper";
import SEO from "components/layout/SEO";
export const _frontmatter = {
  "title": "Bitrise At Razorpay",
  "path": "talks/bitrise",
  "shortInfo": "A walkthrough on how we automated our builds & deployment using bitrise and semantic versioning",
  "position": 4,
  "thumbnail": "./thumbnail.png",
  "videoLength": "17min",
  "place": "Bitrise",
  "date": "4 June '20"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <TalkContent shortInfo="A walkthrough on how we automated our builds & deployment using bitrise and semantic versioning" title="Bitrise at Razorpay" youtubeUrl="https://youtu.be/OBAGn7ArdRw?t=2520" mdxType="TalkContent">
      <SEO title="Bitrise at Razorpay - Chaitanya Deorukhkar" description="A walkthrough on how we automated our builds & deployment using bitrise and semantic versioning" image="talks/bitrise.png" path="talks/bitrise" mdxType="SEO" />
      <h2>{`Significance`}</h2>
      <p>{`This marked my first talk at an international platform. Unfortunately, I couldn't travel to Budapest thanks to the pandemic ☹️`}</p>
      <br />
      <p>{`Having been a long time user of Bitrise, it meant a lot talking `}<em parentName="p">{`at`}</em>{` Bitrise.`}</p>
      <h2>{`Where it all began`}</h2>
      <p>{`Figuring out CI/CD was one of the first things I took up at Razorpay when I joined them in December 2019. Our goal was simple, deploy the app with the click of a button. Whoever deploys it, doesn't need to concern themselves with where to deploy, how to build for different OS or manage accesses to different App Stores.`}</p>
      <br />
      <p>{`Coming up with a solution for all this did not only require automation pipelines
but we had to establish processes like app versioning to denote the kind of changes
made in the update to ensure a smooth deployment.`}</p>
      <h2>{`Crunch of time`}</h2>
      <p><a parentName="p" {...{
          "href": "https://twitter.com/akos_hantos"
        }}>{`Akos Hantos`}</a>{` approached me 5 days before the talk. Which meant I had to create a presentation, prepare the talk, and be ready to give my first international talk within 5 days!`}</p>
      <br />
      <p>{`At first, this seemed like an impossible task but I knew I wanted to represent Razorpay and what we've done so far with Bitrise. After a lot of support from my teammates and tons of coffee, I pulled it off 🚀`}</p>
      <h2>{`Credits`}</h2>
      <p><a parentName="p" {...{
          "href": "https://twitter.com/_kamlesh_"
        }}>{`Kamlesh`}</a>{` helped me polish the skeleton of the talk, provided visual and structural feedback to my presentation.`}</p>
      <p><a parentName="p" {...{
          "href": "https://twitter.com/_kamlesh_"
        }}>{`Kamlesh`}</a>{`, `}<a parentName="p" {...{
          "href": "https://twitter.com/bavannawar"
        }}>{`Shridhar`}</a>{` & `}<a parentName="p" {...{
          "href": "https://twitter.com/shubhniksingh"
        }}>{`Shubhnik`}</a>{` helped brainstorm, setup Bitrise and all the processes around it at Razorpay.`}</p>
      <br />
      <br />
      <br />
      <br />
      <h2>{`Featured Tweets`}</h2>
      <TweetWrapper mdxType="TweetWrapper">
        <p><blockquote parentName="p" {...{
            "className": "twitter-tweet",
            "data-dnt": "true"
          }}><p parentName="blockquote" {...{
              "lang": "en",
              "dir": "ltr"
            }}>{`Wow, now this is what we call a BUG! 🐛🔥 `}<br parentName="p"></br>{`The recording of last week’s Bitrise User Group webinar about `}<a parentName="p" {...{
                "href": "https://twitter.com/hashtag/remoteworking?src=hash&ref_src=twsrc%5Etfw"
              }}>{`#remoteworking`}</a>{` & Mobile `}<a parentName="p" {...{
                "href": "https://twitter.com/hashtag/DevOps?src=hash&ref_src=twsrc%5Etfw"
              }}>{`#DevOps`}</a>{` is definitely worth a watch — featuring `}<a parentName="p" {...{
                "href": "https://twitter.com/_anothercoder"
              }}>{`@_anothercoder`}</a>{`, `}<a parentName="p" {...{
                "href": "https://twitter.com/abavisg"
              }}>{`@abavisg`}</a>{`, `}<a parentName="p" {...{
                "href": "https://twitter.com/j_lorfeo"
              }}>{`@j_lorfeo`}</a>{`, `}<a parentName="p" {...{
                "href": "https://twitter.com/HHaandr"
              }}>{`@HHaandr`}</a>{`, and Denis Stark!`}<a parentName="p" {...{
                "href": "https://t.co/dmCfyalSkH"
              }}>{`https://t.co/dmCfyalSkH`}</a></p>{`— Bitrise (@bitrise) `}<a parentName="blockquote" {...{
              "href": "https://twitter.com/bitrise/status/1270271831723950080"
            }}>{`June 9, 2020`}</a></blockquote></p>
        <p><blockquote parentName="p" {...{
            "className": "twitter-tweet",
            "data-dnt": "true"
          }}><p parentName="blockquote" {...{
              "lang": "en",
              "dir": "ltr"
            }}>{`.`}<a parentName="p" {...{
                "href": "https://twitter.com/_anothercoder"
              }}>{`@_anothercoder`}</a>{` at the `}<a parentName="p" {...{
                "href": "https://twitter.com/bitrise"
              }}>{`@bitrise`}</a>{` `}<a parentName="p" {...{
                "href": "https://twitter.com/hashtag/BUG?src=hash&ref_src=twsrc%5Etfw"
              }}>{`#BUG`}</a>{`: "be a little kinder to ourselves - We're not working remotely, we're working during a pandemic"`}<br parentName="p"></br>{`👏👏👏 `}<a parentName="p" {...{
                "href": "https://t.co/T7HJCzbfZJ"
              }}>{`pic.twitter.com/T7HJCzbfZJ`}</a></p>{`— Hendrik Haandrikman 🚀 (@HHaandr) `}<a parentName="blockquote" {...{
              "href": "https://twitter.com/HHaandr/status/1268589251593150465"
            }}>{`June 4, 2020`}</a></blockquote></p>
        <p><blockquote parentName="p" {...{
            "className": "twitter-tweet",
            "data-dnt": "true"
          }}><p parentName="blockquote" {...{
              "lang": "en",
              "dir": "ltr"
            }}>{`Tomorrow I'll be talking at `}<a parentName="p" {...{
                "href": "https://twitter.com/bitrise"
              }}>{`@bitrise`}</a>{` about how we at `}<a parentName="p" {...{
                "href": "https://twitter.com/Razorpay"
              }}>{`@Razorpay`}</a>{` (unintentionally?) prepared ourselves to build, test, and deploy with confidence during this sudden phase of remote work while keeping our sanity.`}<br parentName="p"></br><br parentName="p"></br>{`The event starts at 9:30pm IST.  I hope some of you can be there! `}<a parentName="p" {...{
                "href": "https://t.co/7lUu2fS9FH"
              }}>{`https://t.co/7lUu2fS9FH`}</a></p>{`— Chaitanya Deorukhkar (@_anothercoder) `}<a parentName="blockquote" {...{
              "href": "https://twitter.com/_anothercoder/status/1268218562256273408"
            }}>{`June 3, 2020`}</a></blockquote></p>
        <p><blockquote parentName="p" {...{
            "className": "twitter-tweet",
            "data-dnt": "true"
          }}><p parentName="blockquote" {...{
              "lang": "en",
              "dir": "ltr"
            }}>{`Let's talk mastering asynchronous work of Mobile `}<a parentName="p" {...{
                "href": "https://twitter.com/hashtag/DevOps?src=hash&ref_src=twsrc%5Etfw"
              }}>{`#DevOps`}</a>{` teams, the rise of tools improving remote work, and tackling new challenges with the Bitrise community during our next webinar on June 4 — see the agenda and register here! 🚀👉 `}<a parentName="p" {...{
                "href": "https://t.co/QG4JukK8X7"
              }}>{`https://t.co/QG4JukK8X7`}</a>{` `}<a parentName="p" {...{
                "href": "https://t.co/bGMEilFkdd"
              }}>{`pic.twitter.com/bGMEilFkdd`}</a></p>{`— Bitrise (@bitrise) `}<a parentName="blockquote" {...{
              "href": "https://twitter.com/bitrise/status/1266001324488957952"
            }}>{`May 28, 2020`}</a></blockquote></p>
      </TweetWrapper>
    </TalkContent>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      